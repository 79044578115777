import { useUserStore } from 'store/user.store';
import { mainHeaderLinks as headerLinks, categoryCatalogLink } from 'constants/layouts/header.const';

export function useMainHeaderLinks() {
  const userStore = useUserStore();

  const mainHeaderLinks = computed(() => {
    if (!userStore.isUserLoggedIn) {
      const contactLinkIndex = headerLinks.findIndex((link) => link.to === '/contact/');

      return headerLinks.toSpliced(contactLinkIndex, 1, categoryCatalogLink);
    }

    return headerLinks;
  });

  return { mainHeaderLinks };
}